@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base
{
  @font-face
  {
    font-family: KumbhSans;
    src: url('./font/KumbhSans-Light.ttf');
    font-weight: 300;
  }
  @font-face
  {
    font-family: KumbhSans;
    src: url('./font/KumbhSans-Regular.ttf');
    font-weight: normal;
  }

  @font-face
  {
    font-family: KumbhSans;
    src: url('./font/KumbhSans-Bold.ttf');
    font-weight: bold;
  }

  *
  {
    @apply
      outline-none
      focus:outline-none
      ;
  }

  body
  {
    @apply
      text-white
      ;
  }

  ::-webkit-scrollbar
  {
    @apply
      bg-black
      bg-opacity-50
      w-1.5
      h-1.5
      ;
    }

  ::-webkit-scrollbar-thumb
  {
    @apply
      bg-shade-200
      bg-opacity-70
      ;
  }

  ::-webkit-scrollbar-thumb:hover
  {
    @apply
      bg-shade-200
      bg-opacity-50
      ;
  }

  ::-webkit-scrollbar-thumb:active
  {
    @apply
      bg-shade-200
      bg-opacity-30
      ;
  }

  *
  {
    scrollbar-color: var(--color-shade-500) #00000080;
    scrollbar-width: thin;
  }
}

@layer utilities
{
  .scrollbar-none
  {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
  }

  .scrollbar-none::-webkit-scrollbar
  {
    display: none; /* for Chrome, Safari, and Opera */
  }

  @variants responsive
  {
    .scroll-p-4
    {
      scroll-padding: 1rem;
    }

    .scroll-p-8
    {
      scroll-padding: 2rem;
    }

    .scroll-pb-24
    {
      scroll-padding-bottom: 6rem;
    }

    .scroll-pb-36
    {
      scroll-padding-bottom: 9rem;
    }
  }

  .appearance-none
  {
    -webkit-appearance: none;
  }

  .animate-fade-in
  {
    animation: fade-in .1s linear both;
  }

  .animate-fade-in-delay
  {
    animation: fade-in .1s linear .1s both;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .bg-force-white
  {
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .text-force-white
  {
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .content-stretch
  {
    align-content: stretch;
  }
}

@layer components
{
  @import 'react-toastify/dist/ReactToastify.css';

  @keyframes Toastify__zoomIn {}
  :root
  {
    --toastify-color-info: var(--color-primary);
  }

  .btn
  {
    @apply
      transition
      rounded
      p-4
      font-bold
      disabled:opacity-50
      disabled:cursor-default
      leading-0
      ;
  }

  .btn-light
  {
    @apply
      font-normal
      ;
  }

  .btn-tertiary
  {
    @apply
      bg-shade-800
      text-shade-300
      hover:bg-shade-500
      hover:bg-opacity-50
      hover:text-shade-200
      active:bg-shade-800
      active:bg-opacity-100
      active:text-shade-300
      disabled:bg-shade-800
      focus:outline-shade-800
      focus-visible:outline-shade-500
      ;
  }

  .btn-secondary
  {
    @apply
      bg-shade-700
      text-shade-300
      hover:bg-shade-600
      active:bg-shade-800
      disabled:bg-shade-700
      focus:outline-shade-700
      focus-visible:outline-shade-500
      ;
  }

  .btn-primary
  {
    @apply
      bg-primary
      text-white
      hover:bg-primary-light
      active:bg-primary-dark
      disabled:bg-primary
      focus:outline-primary
      ;
  }

  .btn-danger
  {
    @apply
    bg-red-700
      text-white
      hover:bg-red-500
      active:bg-red-800
      disabled:bg-red-500
      focus:outline-red-700
      ;
  }

  .btn-success
  {
    @apply
      bg-bd
      text-white
      hover:bg-bd-light
      active:bg-bd-dark
      disabled:bg-bd
      focus:outline-bd
      ;
  }

  [type=search]
  {
    @apply
      bg-shade-800
      text-white
      placeholder-shade-300
      focus:outline-shade-800
      rounded-full
      py-1
      pl-4
      pr-8
      ;
  }

  .light [type=text],
  .light [type=search]
  {
    @apply
      bg-shade-700
      focus:outline-shade-700
      ;
  }

  [type=text],
  [type=email],
  [type=number],
  [type=password],
  select,
  .StripeElement
  {
    @apply
      bg-shade-800
      focus:outline-shade-800
      placeholder-shade-300
      text-white
      /* font-bold */
      rounded
      px-2
      py-1
      ;
  }

  .StripeElement--focus
  {
    @apply
      outline-shade-800
      ;
  }

  ::placeholder
  {
    @apply
      font-light
      ;
  }

  [type=text]:read-only,
  [type=email]:read-only,
  [type=number]:read-only,
  [type=password]:read-only,
  select:disabled
  {
    @apply
      bg-shade-800
      bg-opacity-50
      text-shade-500
      focus:outline-shade-800-50
      ;
  }

  ::selection
  {
    @apply
      text-shade-800
      bg-shade-400
      ;
  }

  :focus::selection,
  body:focus ::selection
  {
    @apply
      text-white
      bg-primary
      ;
  }

  [type=number]
  {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  [type=number]::-webkit-inner-spin-button,
  [type=number]::-webkit-outer-spin-button
  {
    -webkit-appearance: none;
  }

  select.appearance
  {
    @apply
      appearance-none
      pr-8
      bg-no-repeat
      ;
    background-image: data-uri(src/icons/ellipsis-v.svg);
    background-size: auto 1rem;
    background-position: right 1rem center;
  }

  [type=checkbox],
  [type=radio]
  {
    @apply
      appearance-none
      focus:outline-shade-300
      w-5
      h-5
      rounded
      transition-colors
      ;
    transition-property: outline-color;
  }

  [type=checkbox]:checked:focus,
  [type=radio]:checked:focus
  {
    @apply
      outline-white
      ;
    outline-style: solid;
  }

  [type=checkbox]::after,
  [type=radio]::after
  {
    content: '';
    @apply
      block
      w-5
      h-5
      rounded
      border-2
      border-shade-300
      bg-shade-800
      transition-colors
      ;
  }

  [type=radio],
  [type=radio]::after
  {
    @apply
      rounded-full
      ;
  }

  [type=checkbox]:checked::after,
  [type=radio]:checked::after
  {
    @apply
      bg-primary
      border-white
      ;
  }

  .input-group
  {
    @apply
      flex
      focus-within:outline-shade-800
      rounded
      ;
  }

  .input-group > *
  {
    @apply
      rounded-none
      first:rounded-l
      last:rounded-r
      ;
    @apply focus:outline-none !important;
  }

  .input-group > span,
  .input-group .addon
  {
    @apply
      bg-shade-800
      bg-opacity-50
      px-2
      py-1
      ;
  }

  ul.menu > a,
  ul.menu > div,
  ul.menu > button
  {
    @apply
      list-item
      bg-shade-400
      text-shade-200
      hover:bg-shade-300
      hover:text-white
      /* font-bold */
      px-3
      py-1
      first:rounded-t
      last:rounded-b
      transition-colors
      whitespace-nowrap
      text-center
      ;
  }

  ul.menu > button.text-only
  {
    @apply
       pointer-events-none
       font-light
       ;
  }

  ul.menu > a.danger,
  ul.menu > div.danger,
  ul.menu > button.danger
  {
    @apply
      text-red-400
      hover:text-white
      hover:bg-red-600
      ;
  }

  ul.menu > a.warning,
  ul.menu > div.warning,
  ul.menu > button.warning
  {
    @apply
      text-yellow-500
      hover:text-white
      hover:bg-yellow-600
      ;
  }

  ul.menu > a.success,
  ul.menu > div.success,
  ul.menu > button.success
  {
    @apply
      text-bd
      hover:text-white
      hover:bg-bd
      ;
  }

  ul.menu > a.active,
  ul.menu > div.active,
  ul.menu > button.active
  {
    @apply
      text-white
      ;
  }

  ul.menu hr
  {
    @apply
      list-item
      border-0
      bg-shade-400
      px-2
      py-1
      ;
  }

  ul.menu hr::before
  {
    content: '';
    @apply
      bg-gradient-to-r
      from-shade-400
      to-shade-400
      via-shade-200
      block
      h-0.5
      ;
  }

  [data-popper-placement^=bottom] ul.menu > a:first-child::before,
  [data-popper-placement^=bottom] ul.menu > div:first-child::before,
  [data-popper-placement^=bottom] ul.menu > button:first-child::before,
  [data-popper-placement^=top] ul.menu > a:last-child::before,
  [data-popper-placement^=top] ul.menu > div:last-child::before,
  [data-popper-placement^=top] ul.menu > button:last-child::before
  {
    content: '';
    @apply
      border-8
      border-shade-400
      block
      absolute
      w-4
      h-4
      transition-colors
      ;
    z-index: -1;
  }

  [data-popper-placement^=bottom] ul.menu > a:first-child::before,
  [data-popper-placement^=bottom] ul.menu > div:first-child::before,
  [data-popper-placement^=bottom] ul.menu > button:first-child::before
  {
    @apply
      top-0
      ;
    margin-top: -10px;
    border-top-color: transparent !important;
  }

  [data-popper-placement^=top] ul.menu > a:last-child::before,
  [data-popper-placement^=top] ul.menu > div:last-child::before,
  [data-popper-placement^=top] ul.menu > button:last-child::before
  {
    @apply
      bottom-0
      ;
    margin-bottom: -10px;
    border-bottom-color: transparent !important;
  }

  [data-popper-placement^=bottom] ul.menu:not(.right) :first-child::before,
  [data-popper-placement^=top] ul.menu:not(.right) :last-child::before
  {
    @apply
      right-0
      ;
    border-left-color: transparent !important;
  }

  [data-popper-placement^=bottom] ul.menu.right :first-child::before,
  [data-popper-placement^=top] ul.menu.right :last-child::before
  {
    @apply
      left-0
      ;
    border-right-color: transparent !important;
  }

  [data-popper-placement^=bottom] ul.menu :first-child:hover::before,
  [data-popper-placement^=top] ul.menu :last-child:hover::before
  {
    @apply
      border-shade-300
      ;
  }

  [data-popper-placement^=bottom] ul.menu .danger:first-child:hover::before,
  [data-popper-placement^=top] ul.menu .danger:last-child:hover::before
  {
    @apply
      border-red-600
      ;
  }

  ul.sidebar-nav a,
  ul.sidebar-nav button
  {
    @apply
      px-4
      xl:px-8
      py-2
      xl:py-3
      transition-all
      block
      w-full
      text-left
      whitespace-nowrap
      ;
  }

  ul.sidebar-nav a.flex,
  ul.sidebar-nav button.flex
  {
    display: flex;
  }

  ul.sidebar-nav a:hover,
  ul.sidebar-nav button:hover
  {
    @apply
      bg-shade-600
      bg-opacity-50
      text-shade-200
      ;
  }

  ul.sidebar-nav a.active,
  ul.sidebar-nav button.active
  {
    @apply
      bg-shade-600
      text-white
      font-bold
      ;
  }

  .modal-open
  {
    @apply overflow-hidden;
  }

  .modal-overlay
  {
    @apply
      fixed
      inset-0
      transition-colors
      duration-200
      bg-black
      bg-opacity-0
      backdrop-filter
      backdrop-blur-none
    ;
    transition-property: background-color backdrop-filter;
  }

  .modal-overlay.modal-open
  {
    @apply
      bg-opacity-70
      backdrop-blur-xs
    ;
  }

  .modal-overlay.modal-closing
  {
    @apply
      bg-opacity-0
      backdrop-blur-none
    ;
  }

  .modal-content
  {
    @apply
      absolute
      top-1/2
      left-1/2
      right-auto
      bottom-auto
      transform-gpu
      -translate-x-1/2
      rounded
      opacity-0
      transition
      duration-200
      overflow-hidden
      flex
      flex-col
      bg-shade-800
      text-shade-400
      outline-none
    ;
    --tw-translate-y: calc(-50% - 1rem);
    width: calc(100% - 2rem);
    max-height: calc(100% - 2rem);
  }

  .modal-open .modal-content
  {
    @apply
      -translate-y-1/2
      opacity-100
    ;
  }

  .modal-closing .modal-content
  {
    @apply
      opacity-0
    ;
    --tw-translate-y: calc(-50% + 1rem);
  }

  table.table
  {
    @apply
      text-sm
      text-left
      ;
  }

  table.table thead tr
  {
    @apply
      border-b-2
      border-shade-700
      ;
  }

  table.table th,
  table.table td
  {
    @apply
      p-1
      ;
  }

  table.table tbody tr
  {
    @apply
      hover:bg-shade-800
      transition-colors
      ;
  }

  table.table tbody tr:nth-child(even)
  {
    @apply
      bg-shade-800
      bg-opacity-50
      hover:bg-shade-700
      hover:bg-opacity-100
      ;
  }

  table.table.table-light thead tr
  {
    @apply
      border-shade-500
      ;
  }

  table.table.table-light tbody tr
  {
    @apply
      hover:bg-shade-600
      ;
  }

  table.table.table-light tbody tr:nth-child(even)
  {
    @apply
      bg-shade-600
      bg-opacity-50
      hover:bg-shade-500
      hover:bg-opacity-100
      ;
  }

  table.table.table-light .tablesorter.text-shade-600
  {
    @apply
      text-shade-400
      ;
  }

  .field-info p
  {
    @apply
      mb-2
      ;
  }
  .tooltip
  {
    @apply
      rounded-sm
      text-xs
      font-bold
      p-1
      py-0.5
      hidden
      pointer-events-none
      opacity-0
      ;
  }

  .tooltip.block
  {
    @apply opacity-80;
  }

  .tooltip .arrow
  {
    @apply absolute;
    background: inherit;
  }

  .tooltip[data-popper-placement=top] .arrow { @apply bottom-0; }
  .tooltip[data-popper-placement=bottom] .arrow { @apply top-0; }
  .tooltip[data-popper-placement=left] .arrow { @apply right-0; }
  .tooltip[data-popper-placement=right] .arrow { @apply left-0; }

  .tooltip .arrow::before
  {
    content: '';
    @apply
      w-1.5
      h-1.5
      transform-gpu
      -translate-x-1/2
      -translate-y-1/2
      rotate-45
      block
      absolute
      ;
    opacity: unset !important;
    background: inherit;
  }
}

@layer utilities
{
  .no-reveal::-ms-reveal
  {
    display: none;
  }

  /* https://css-tricks.com/easing-linear-gradients/ */
  .scrim-black
  {
    --tw-gradient-stops:
      rgba(0,0,0,calc(1.0 * var(--tw-bg-opacity))) 0%,
      rgba(0,0,0,calc(0.738 * var(--tw-bg-opacity))) 19%,
      rgba(0,0,0,calc(0.541 * var(--tw-bg-opacity))) 34%,
      rgba(0,0,0,calc(0.382 * var(--tw-bg-opacity))) 47%,
      rgba(0,0,0,calc(0.278 * var(--tw-bg-opacity))) 56.5%,
      rgba(0,0,0,calc(0.194 * var(--tw-bg-opacity))) 65%,
      rgba(0,0,0,calc(0.126 * var(--tw-bg-opacity))) 73%,
      rgba(0,0,0,calc(0.075 * var(--tw-bg-opacity))) 80.2%,
      rgba(0,0,0,calc(0.042 * var(--tw-bg-opacity))) 86.1%,
      rgba(0,0,0,calc(0.021 * var(--tw-bg-opacity))) 91%,
      rgba(0,0,0,calc(0.008 * var(--tw-bg-opacity))) 95.2%,
      rgba(0,0,0,calc(0.002 * var(--tw-bg-opacity))) 98.2%,
      rgba(0,0,0,calc(0.0 * var(--tw-bg-opacity))) 100%
      ;
  }

  .h-min
  {
    height: min-content;
  }

  .break-pretty
  {
    overflow-wrap: anywhere;
  }
}

.md p { @apply leading-snug my-1; }
.md h1 { @apply font-bold text-2xl; }
.md h2 { @apply font-bold text-xl; }
.md h3 { @apply font-bold text-lg; }
.md h4 { @apply font-bold; }
.md a[href] { @apply text-primary !important; }
.md blockquote { @apply p-2 border-l-2 border-current; }
.md ul { @apply list-outside ml-4 list-disc; }
.md ol { @apply list-outside ml-4 list-decimal; }
.md hr { @apply my-2 border-current; }
.md code { @apply bg-shade-900 text-shade-400 rounded px-1 select-text; }
.md pre > code { @apply block overflow-x-auto; }
